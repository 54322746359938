import React, { useEffect } from 'react';
import img from '../../assets/animations/aistack.json'
import Lottie from 'lottie-react';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
const Hero = () => {
  const controlsSection = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controlsSection.start('visible');
    }
  }, [controlsSection, inView]);

  const textAnimation = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1 }
    }
  };

  const imageAnimation = {
    hidden: { opacity: 0, x: 100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1 }
    }
  };

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3
      }
    }
  };
  return (
    <section ref={ref} className="py-10 px-32 w-full mt-36 font-code max-md:py-0 max-md:px-5 max-md:mt-28 max-md:max-w-full">
  <div className="flex gap-5 max-md:flex-col items-start">
    <div className="flex flex-col w-[45%] max-md:ml-0 max-md:w-full">
      <motion.div initial="hidden" animate={controlsSection} variants={containerVariants} className="mt-10 flex flex-col max-md:mt-10 max-md:max-w-full">
        <motion.h1 variants={textAnimation} className="text-4xl font-bold leading-[45px] max-md:text-3xl max-md:max-w-full">
          Passionate professionals delivering impactful software
        </motion.h1>
        <motion.p variants={textAnimation} className="mt-12 max-md:text-sm text-lg leading-8 max-md:mt-10 max-md:mr-2.5">
          Over the years, we have evolved into a full-service tech company specializing in software development, AI & ML, and cloud services. Our mission is to empower businesses with innovative technology solutions that drive success.
        </motion.p>
      </motion.div>
    </div>
    <motion.div initial="hidden" animate={controlsSection} variants={imageAnimation} className="flex flex-col ml-5 w-[55%] max-md:ml-0 max-md:w-full"> {/* Changed w-[65%] to w-[55%] for better balance */}
      <Lottie animationData={img} className="w-full h-auto max-md:mt-10 max-md:max-w-full" />
    </motion.div>
  </div>
</section>
  );
};

export default Hero;