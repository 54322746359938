import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Feature = ({ feature, imageOnLeft }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, x: 0 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: imageOnLeft ? 100 : -100 }}
      animate={controls}
      transition={{ duration: 0.8 }}
    >
      <h3 className="text-xl max-md:text-lg font-bold">{feature.title}</h3>
      <p className="mt-2 text-sm max-md:text-xs">{feature.description}</p>
    </motion.div>
  );
};

const FeatureSection = ({ image, title, description, features, imageOnLeft = true }) => {
  const controls = useAnimation();
  const [titleRef, titleInView] = useInView({ triggerOnce: true });
  const [descRef, descInView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (titleInView) {
      controls.start({ opacity: 1, x: 0 });
    }
  }, [controls, titleInView]);

  return (
    <section className="mt-36 px-20 font-code w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        {imageOnLeft ? (
          <>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={titleInView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.8 }}
              className="flex flex-col w-5/12 mt-10 max-md:ml-0 max-md:w-full"
            >
              <img
                src={image}
                alt="Feature"
                className="w-full h-auto object-cover rounded-md" 
              />
            </motion.div>
            <div className="flex flex-col ml-5 w-7/12 max-md:ml-0 max-md:w-full">
              <motion.h2
                ref={titleRef}
                initial={{ opacity: 0, x: imageOnLeft ? 100 : -100 }}
                animate={titleInView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.8 }}
                className="text-4xl max-md:text-3xl font-bold leading-[54px] text-[#076EAB]"
              >
                {title}
              </motion.h2>
              <motion.p
                ref={descRef}
                initial={{ opacity: 0, x: imageOnLeft ? 100 : -100 }}
                animate={descInView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.8 }}
                className="mt-7 leading-7 text-base max-md:text-sm"
              >
                {description}
              </motion.p>
              <div className="mt-10 space-y-5">
                {features.map((feature, index) => (
                  <Feature key={index} feature={feature} imageOnLeft={imageOnLeft} />
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col w-7/12 max-md:ml-0 max-md:w-full">
              <motion.h2
                ref={titleRef}
                initial={{ opacity: 0, x: imageOnLeft ? 100 : -100 }}
                animate={titleInView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.8 }}
                className="text-4xl max-md:text-3xl font-bold leading-[54px] text-[#076EAB]"
              >
                {title}
              </motion.h2>
              <motion.p
                ref={descRef}
                initial={{ opacity: 0, x: imageOnLeft ? 100 : -100 }}
                animate={descInView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.8 }}
                className="mt-7 leading-7 text-base max-md:text-sm"
              >
                {description}
              </motion.p>
              <div className="mt-10 space-y-5">
                {features.map((feature, index) => (
                  <Feature key={index} feature={feature} imageOnLeft={imageOnLeft} />
                ))}
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={titleInView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.8 }}
              className="flex flex-col ml-5 mt-10 w-5/12 max-md:ml-0 max-md:w-full" 
            >
              <img
                src={image}
                alt="Feature"
                className="w-full h-auto object-cover rounded-md" 
              />
            </motion.div>
          </>
        )}
      </div>
    </section>
  );
};

export default FeatureSection;











// const lottieAnimation = image ? (
  //   <motion.div
  //     ref={ref}
  //     initial={{ opacity: 0, x: imageOnLeft ? -100 : 100 }}
  //     animate={controls}
  //     transition={{ duration: 0.8 }}
  //     className="w-full h-3/4 rounded-md object-cover max-md:mt-10 max-md:max-w-full"
  //   >
  //     <Lottie animationData={image} loop={true} />
  //   </motion.div>
  // ) : (
  //   <div>Loading animation...</div>
  // );