import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
// import DotAnimation from './DotAnimation';
import digitaltransform from '../../assets/images/aihand.png';
function InfoSection() {
  const controlsHeading = useAnimation();
  const controlsParagraph = useAnimation();
  const controlsImage = useAnimation();
  const { ref: refHeading, inView: inViewHeading } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: refParagraph, inView: inViewParagraph } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: refImage, inView: inViewImage } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inViewHeading) {
      controlsHeading.start('visible');
    }
  }, [controlsHeading, inViewHeading]);

  useEffect(() => {
    if (inViewParagraph) {
      controlsParagraph.start('visible');
    }
  }, [controlsParagraph, inViewParagraph]);

  useEffect(() => {
    if (inViewImage) {
      controlsImage.start('visible');
    }
  }, [controlsImage, inViewImage]);

  const textAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 1 }
    }
  };

  const imageAnimation = {
    hidden: { opacity: 0, x: 100 },
    visible: { 
      opacity: 1, 
      x: 0, 
      transition: { duration: 1.5 }
    }
  };

  return (
    <section ref={refHeading} className="flex flex-col mt-32 self-center font-code px-28 w-full max-md:px-5 max-md:mt-0 max-md:max-w-full">
      {/* <DotAnimation/> */}
        <motion.h2 initial="hidden" animate={controlsHeading}  variants={textAnimation} className="self-center mt-10 text-4xl max-md:text-3xl font-bold leading-10 text-center w-[826px] max-md:mt-10 max-md:max-w-full">
          OUR SERVICE
        </motion.h2>

      <motion.div 
        ref={refParagraph} 
        initial="hidden" 
        animate={controlsParagraph} 
        variants={textAnimation} 
        className="self-center mt-16 max-md:mt-10 leading-8 max-md:max-w-full"
      >
        <motion.p variants={textAnimation} className="text-lg max-md:text-sm max-md:text-left text-center leading-8 max-md:max-w-full">
          At Zredi Technology, we offer a comprehensive suite of services designed to drive digital 
          transformation and innovation. From cutting-edge AI solutions to robust cloud infrastructures, 
          We are dedicated to transforming your digital vision into reality with our expertise spans across 
          various cutting-edge technologies, ensuring that your business stays ahead in the ever-evolving tech landscape.
        </motion.p>
      </motion.div>

      <motion.div 
        ref={refImage} 
        initial="hidden" 
        animate={controlsImage} 
        variants={imageAnimation} 
        className="self-center max-w-full rounded-md mt-16 w-[934px] md:w-full max-md:mt-10 z-[2]"
      >
        <img className='h-auto w-full' loading="lazy" src={digitaltransform} alt="digital transformation" />
      </motion.div>
    </section>
  );
}

export default InfoSection;
