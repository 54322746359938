import React, { useEffect } from 'react';
import about from '../../assets/svgs/team.svg';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

function AboutSection() {
  const controlsSection = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const MotionLink = motion(Link);
  useEffect(() => {
    if (inView) {
      controlsSection.start('visible');
    }
  }, [controlsSection, inView]);

  const textAnimation = {
    hidden: { opacity: 0, x: 100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1 }
    }
  };
  

  const imageAnimation = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1 }
    }
  };

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  return (
    <section ref={ref} className="mt-40 px-20 font-code max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        <motion.div initial="hidden" animate={controlsSection} variants={imageAnimation} className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
          <img src={about} alt="loading" />
        </motion.div>
        <div className="flex flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full">
          <motion.div initial="hidden" animate={controlsSection} variants={containerVariants} className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
            <motion.h2 variants={textAnimation} className="uppercase text-4xl max-md:text-3xl font-bold max-md:max-w-full">
              Elevate Your Business with Our Expertise
            </motion.h2>
            <motion.p variants={textAnimation} className="mt-14 text-lg max-md:text-sm max-md:mt-10 max-md:max-w-full">
              Discover how Zredi Technology can transform your business operations with our innovative solutions. 
              From scalable software development to advanced AI applications, we offer tailored services to drive 
              your success in the digital era.
            </motion.p>
            <MotionLink initial={false} whileHover={ {scale:1.1}} variants={textAnimation} to="/contact" className="self-start px-4 py-3 rounded-full text-white mt-14 text-base bg-[#076EAB] max-md:px-5 max-md:mt-10">
              Get in Touch
            </MotionLink>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
