

import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './pages/HomeScreen';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './hooks/ScrollToTop';
import { useEffect, useState } from 'react';
import Blog from './components/Blog/Blog';
import BlogDetail from './components/Blog/BlogDetail';
import ErrorPage from './pages/Error';

function App() {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'dark';
    setTheme(savedTheme);
    document.documentElement.setAttribute('data-theme', savedTheme);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };
  return (
    
    <BrowserRouter>
    <ScrollToTop/>
      <Header toggleTheme={toggleTheme} theme={theme}/>
      <Routes>
        <Route path='/' element={<HomeScreen/>}/>
        <Route path='/about' element={<About theme={theme}/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/contact' element={<Contact theme={theme}/>}/>
        {/* <Route path="/blog" element={<Blog/>}/> */}
        {/* <Route path='/blog/:id' element={<BlogDetail/>}/> */}
        <Route path='*' element={<ErrorPage />} />
      </Routes>
      <Footer/>
    </BrowserRouter>

  );
}

export default App;
