import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { RiTeamFill } from 'react-icons/ri';
import { SiNovu } from 'react-icons/si';
import { FaPersonArrowUpFromLine } from 'react-icons/fa6';
import { FaCheckCircle } from 'react-icons/fa';


const FeatureSection = ({theme}) => {
  const controlsHeading = useAnimation();
  const { ref: refHeading, inView: inViewHeading } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inViewHeading) {
      controlsHeading.start('visible');
    }
  }, [controlsHeading, inViewHeading]);

  const textAnimation = {
    hidden: { opacity: 0, y: 70 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1 }
    }
  };

  return (
    <section className="feature-section flex flex-col font-code mt-20 w-full max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col items-center py-20 w-full bg-opacity-10 max-md:max-w-full">
        <div className="flex flex-col px-5 mt-2 w-full max-md:max-w-full">
          <motion.h2 ref={refHeading} initial="hidden" animate={controlsHeading} variants={textAnimation} className="uppercase text-4xl font-bold text-center max-md:max-w-full">
            Why work with us
          </motion.h2>
        </div>

        <div className="mt-24 w-full">
        <VerticalTimeline lineColor={theme === 'dark' ? 'white' : 'black'}>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#076EAB', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Expert Team"
            iconStyle={{ background: '#076EAB', color: '#ffffff' }}
            icon={<RiTeamFill />}
          >
            <p>
            Our team consists of highly skilled professionals with extensive experience in various 
            domains. We are passionate about technology and committed to delivering the best solutions for our clients.            
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#076EAB', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Customer-Centric Approach"
            iconStyle={{ background: '#076EAB', color: '#ffffff' }}
            icon={<FaPersonArrowUpFromLine />}
          >
            <p>
            We believe in building long-term relationships with our clients. Our customer-centric 
            approach ensures that we understand your needs and deliver solutions that exceed your expectations.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#076EAB', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Innovative Solutions"
            iconStyle={{ background: '#076EAB', color: '#ffffff' }}
            icon={<SiNovu/>}
          >
            <p>
            We stay ahead of the curve by continuously exploring and adopting the latest technologies. 
            Our innovative solutions are designed to help you stay competitive and achieve your business goals.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#076EAB', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Quality Assurance"
            iconStyle={{ background: '#076EAB', color: '#ffffff' }}
            icon={<FaCheckCircle />}
          >
            <p>
            Quality is at the core of everything we do. We follow stringent quality assurance processes 
            to ensure that our solutions are reliable, secure, and scalable.
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
