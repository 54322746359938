import React from 'react';
import Lottie from 'lottie-react';
import bganimation from '../../assets/animations/ani6.json';
import lineanimation from '../../assets/animations/line.json';

const BackgroundAnimation = () => {
  return (
    <>
    {/* <div className='absolute top-80 right-[-165px] w-5/6 h-5/6 overflow-hidden z-[1] hidden md:block'>
      <Lottie animationData={bganimation} loop={true} className="w-full h-full" />
    </div> */}
    
    <div className="absolute top-[165px] left-24 w-[375px] h-20 overflow-hidden z-[1] hidden md:block">
        <Lottie animationData={lineanimation} loop={true}/>
    </div>
    </>
  );
};


export default BackgroundAnimation;
