import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';

const StatCard = ({ IconComponent, number, label }) => {
  const [inView, setInView] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.unobserve(ref.current);
        }
      },
      { threshold: 0.3 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <div ref={ref} className="flex flex-col items-center max-md:flex-col max-md:mr-5 max-md:ml-5 max-md:items-center max-md:gap-2 max-md:scale-75 ">
      <IconComponent size={50} className="mb-2 text-[#076EAB]" />
      <h5 className="text-4xl font-bold max-md:text-base">
        {inView ? <CountUp end={parseInt(number)} duration={2} /> : '0'}+
      </h5>
      <h3 className="text-3xl max-md:text-base">{label}</h3>
    </div>
  )
};

export default StatCard;