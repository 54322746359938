import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/images/zredi logo-02.png'
import { FaMoon, FaSun } from 'react-icons/fa6';

function Header({ toggleTheme, theme }) {
  const [activeLink, setActiveLink] = useState(null);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const MotionLink = motion(Link);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const drawerVariants = {
    hidden: { x: '100%' },
    visible: { x: 0 }
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const links = [
    { path: "/", label: "Home" },
    { path: "/about", label: "About Us" },
    { path: "/services", label: "Services" },
    // { path: "/blog", label: "Blogs" },
    { path: "/contact", label: "Contact Us" },
  ];

  return (
    <header className="fixed top-0 left-0 font-sora right-0 backdrop-blur bg-black bg-opacity-5 flex justify-between items-center max-md:px-5 px-20 py-4 w-full z-50">
      <img src={logo} alt="Logo" className='w-48 h-auto max-md:w-40'/>
      <nav className={`hidden md:flex gap-11 items-center text-base `}>
      {links.map((link) => (
        <motion.div
          key={link.path}
          className="relative "
          onClick={() => setActiveLink(link.path)}
        >
          <motion.div
            whileHover={{ scale: 1.2, color: '#076EAB' }}
            className={`self-stretch font-bold my-auto`}
          >
            <Link className='text-[var(--nav-text)]' to={link.path}>{link.label}</Link>
          </motion.div>
          {activeLink === link.path && (
            <motion.div initial={{opacity:0, scale: 0.0}} animate={{opacity:1, scale: 1.1}} transition={{duration: 0.3}}
            className="absolute left-0 right-0 bottom-0 h-[3px] bg-[#076EAB]"
          />
          )}
        </motion.div>
      ))}
      <button onClick={toggleTheme}>{theme === 'dark'? <FaSun size={20} /> : <FaMoon size={20} />}</button>
    </nav>
    <div className="flex items-center md:hidden max-md:space-x-4">
        <button onClick={toggleTheme} className="text-2xl">
        {theme === 'dark' ? <FaSun /> : <FaMoon />}
        </button>
        <button onClick={toggleMenu} className="md:hidden text-2xl">
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>
      {isMenuOpen && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={drawerVariants}
          className="fixed top-0 right-0 h-screen w-1/2 md:w-1/2 bg-gray-700 flex flex-col items-center p-10 z-50"
        >
          <button onClick={toggleMenu} className="text-2xl mb-8 self-end">
            <FaTimes color='white' />
          </button>
          <div className="flex flex-col items-center w-full">
            <MotionLink  to="/" className="self-stretch my-5 text-white" onClick={toggleMenu}>Home</MotionLink>
            <MotionLink  to="/about" className="self-stretch my-5 text-white" onClick={toggleMenu}>About us</MotionLink>
            <MotionLink  to="/services" className="self-stretch my-5 text-white" onClick={toggleMenu}>Services</MotionLink>
            <MotionLink  to="/contact" className="mt-5 px-3 py-3 text-sm bg-[#076EAB] rounded-[40px] text-white " onClick={toggleMenu}>
              Contact Us
            </MotionLink>
          </div>
        </motion.div>
      )}
    </header>
  );
}

export default Header;
