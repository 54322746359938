import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaMobileAlt, FaDesktop, FaCloud, FaBrain, FaCube, FaGamepad, FaClone, FaRegClone } from 'react-icons/fa';
import { RiCheckboxMultipleBlankFill } from 'react-icons/ri';
import { LuBrainCircuit, LuCircuitBoard } from 'react-icons/lu';

const services = [
  { icon: FaMobileAlt, title: "App Development" },
  { icon: FaDesktop, title: "Web Development" },
  { icon: FaGamepad, title: "Game Development" },
  { icon: FaCloud, title: "Cloud Services" },
  { icon: LuBrainCircuit , title: "AI & ML Solutions" },
  // { icon: RiCheckboxMultipleBlankFill , title: "Digital Twin"},
  { icon: LuCircuitBoard , title: "IoT Solutions" },
];

function ServiceGrid() {
  const controlsHeading = useAnimation();
  const controlsParagraph = useAnimation();
  const controlsServices = useAnimation();
  const { ref: refHeading, inView: inViewHeading } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: refParagraph, inView: inViewParagraph } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: refServices, inView: inViewServices } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inViewHeading) {
      controlsHeading.start('visible');
    }
  }, [controlsHeading, inViewHeading]);

  useEffect(() => {
    if (inViewParagraph) {
      controlsParagraph.start('visible');
    }
  }, [controlsParagraph, inViewParagraph]);

  useEffect(() => {
    if (inViewServices) {
      controlsServices.start('visible');
    }
  }, [controlsServices, inViewServices]);

  const textAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 1 }
    }
  };

  const serviceAnimation = {
    hidden: { opacity: 0,scale:0},
    visible: { 
      opacity: 1, 
      scale: 1, 
      x: 0,
      transition: { duration: 1 }
    }
  };

  const iconHoverAnimation = {
    hover: {
      rotate: 360,
      transition: { duration: 1 }
    }
  };

  return (
    <section className='self-center font-code px-28 w-full max-md:px-5 max-md:max-w-full'>
      <motion.h2 ref={refHeading} initial="hidden" animate={controlsHeading}  variants={textAnimation} className="self-center uppercase mt-20 text-center text-4xl max-md:text-3xl font-bold leading-10 w-full max-md:mt-10 max-md:max-w-full">
        Innovating for a Smarter Tomorrow
      </motion.h2>
      <motion.p ref={refParagraph} initial="hidden" animate={controlsParagraph} variants={textAnimation} className="self-center text-center max-md:text-left max-md:text-sm mt-16 text-lg leading-8 max-md:mt-10 max-md:max-w-full">
        Discover the range of innovative services we offer to help your business thrive. 
        From cutting-edge software solutions to expert consultancy, our services are designed 
        to meet the diverse needs of businesses in today’s rapidly evolving digital landscape.
      </motion.p>
      <div ref={refServices} className="flex flex-col md:flex-row items-center justify-center mt-24 w-full text-base max-md:mt-10 max-md:max-w-full">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="flex flex-col items-center max-md:mt-5 max-w-full text-center w-[218px]"
            initial="hidden"
            animate={controlsServices}
            variants={serviceAnimation}
          >
            <motion.div
              className="flex items-center justify-center w-[65px] h-[65px] rounded-full bg-[#076EAB]"
              whileHover="hover"
              variants={iconHoverAnimation}
            >
              <service.icon size="35" className="text-white" />
            </motion.div>
            <div className="mt-10 max-md:mt-5">{service.title}</div>
          </motion.div>
        ))}
      </div>
    </section>
  );
}

export default ServiceGrid;
