import React from 'react';
import Hero from '../components/Contact/Hero';
import ContactForm from '../components/Contact/ContactForm';

export default function Contact({theme}) {
  return (
    <>
      <div>
        <Hero />
        <ContactForm theme={theme}/>
      </div>
      </>
  );
}
