import React from 'react';
import Lottie from 'lottie-react';
import errorAnimation from '../assets/animations/404.json';

const ErrorPage = () => {
  return (
    <div className='flex items-center justify-center max-md:mt-20 mt-0'>
      <Lottie animationData={errorAnimation} className='max-md:h-full max-md:w-full h-1/2 w-1/2 mt-10' />
    </div>
  );
};

export default ErrorPage;