import React, { useRef, useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from '../../assets/animations/bot.json';
import bganimation from '../../assets/animations/ani6.json';
import {motion} from 'framer-motion'

const HoverLottie = () => {
  const lottieRef = useRef();

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.stop();
    }
  }, []);

  const handleMouseEnter = () => {
    if (lottieRef.current) {
      lottieRef.current.play(); 
    }
  };

  const handleMouseLeave = () => {
    if (lottieRef.current) {
      lottieRef.current.stop(); 
    }
  };

  return (
    <>
      {/* This div will be animated by Framer Motion */}
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
        className="flex flex-col items-center justify-center mb-20 w-[59%] max-md:mb-10 md:mt-10 max-md:ml-0 max-md:w-full z-[2]"
      >
        {/* Hover effect for first Lottie animation */}
        <div
          className='z-[2]'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ width: '400px', height: '400px' }}
        >
          <Lottie
            animationData={animationData}
            loop={true}
            lottieRef={lottieRef}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </motion.div>

      {/* Background Lottie animation unaffected by Framer Motion */}
      <Lottie 
        animationData={bganimation} 
        loop={true} 
        className="w-full h-full absolute md:block hidden top-80" 
      />
    </>
  );
};

export default HoverLottie;
