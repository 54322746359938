import React from 'react';
import {motion} from 'framer-motion'
import BackgroundAnimation from './BgAnimation';
import HoverLottie from './HoverAnimation';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import bganimation from '../../assets/animations/ani6.json';

const textAnimation = {
  hidden: { opacity: 0, y: 25 },
  show: { 
    opacity: 1, 
    y: 0, 
    transition: { 
      staggerChildren: 0.3,
      duration: 1 
    } 
  }
};

const childAnimation = {
  hidden: { opacity: 0, y: 25 },
  show: { 
    opacity: 1, 
    y: 0, 
    transition: { duration: 0.5 }
  }
};


function Hero() {
  const MotionLink = motion(Link);
  return (
    <section className="relative flex flex-col self-center font-code px-28 pt-32 pb-20 max-md:pb-0 w-full max-md:mt-28 max-md:pt-0 max-md:px-5 max-md:max-w-full">
      <BackgroundAnimation/>
      <div className="max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div  className="flex flex-col mt-10 w-[41%] max-md:mt-0 max-md:w-full">
            <motion.div initial="hidden" animate="show" variants={textAnimation} className="flex flex-col text-base font-medium max-md:max-w-full">
              <motion.h2 variants={childAnimation} className="self-start text-[#076EAB] text-xl mb-3 font-bold">
                Welcome to Zredi Technology
              </motion.h2>
              <motion.h1 variants={childAnimation} className=" z-[2] text-4xl font-bold leading-[45px] max-md:max-w-full max-md:text-3xl max-md:leading-[40px]">
                Driving Digital Transformation and Innovation
              </motion.h1>
              <motion.p variants={childAnimation} className="mt-10 leading-7 text-lg max-md:text-sm max-md:mt-10 max-md:max-w-full">
                Our comprehensive suite of services designed to meet the diverse needs of businesses 
                in today’s fast-paced technological landscape. Whether you're looking to enhance your 
                digital presence or leverage the latest technologies, we have you covered.
              </motion.p>
              <MotionLink whileHover={{ scale: 1.1, }} variants={childAnimation} to="/services" className="self-start px-5 py-4 mt-12 text-white bg-[#076EAB] rounded-[40px] max-md:px-5 max-md:mt-10">
                Get more details
              </MotionLink>
            </motion.div>
          </div>
          <motion.div className="flex flex-col items-center justify-center mb-20  w-[59%] max-md:mb-10 max-md:ml-0 max-md:w-full z-[2]">
            <HoverLottie/>
          </motion.div>         
        </div>
      </div>
    </section>
  );
}

export default Hero;