import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const features = [
  { title: "Software Development", description: "Tailored solutions for robust, scalable applications. From custom software to enterprise applications, we ensure high performance and quality." },
  { title: "AI & ML", description: "Advanced AI and machine learning solutions to drive innovation, automate processes and enhance decision-making capabilities." },
  { title: "Cloud Services", description: "End-to-end cloud solutions including migration, management and security to enhance agility and reduce costs." },
];

const featureVariants = {
  hidden: (direction) => ({
    opacity: 0,
    x: direction === 'left' ? -100 : direction === 'right' ? 100 : 0,
    scale: direction === 'center' ? 1 : 1,
  }),
  visible: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: { duration: 1 },
  },
};

const FeatureCard = ({ feature, index, setHoveredIndex, hoveredIndex }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      key={index}
      className={`feature-card flex flex-col w-[25%] max-md:w-full`}
      custom={index === 0 ? 'left' : index === 1 ? 'center' : 'right'}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={featureVariants}
      whileHover={{ scale: 1.2, zIndex: 10 }}
      onHoverStart={() => setHoveredIndex(index)}
      onHoverEnd={() => setHoveredIndex(null)}
      style={{
        filter: hoveredIndex !== null && hoveredIndex !== index ? 'blur(5px)' : 'none',
        transition: 'filter 0.3s',
      }}
    >
      <div className="flex flex-col grow text-sm max-md:mt-10 p-5 bg-[#076EAB] bg-opacity-50 rounded-2xl shadow-lg transition-shadow duration-300">
        <h3 className="mt-5 text-center text-2xl font-bold">{feature.title}</h3>
        <p className="mt-8 text-left leading-7">
          {feature.description}
        </p>
      </div>
    </motion.div>
  );
};

const FeatureSection = () => {
  const controlsHeading = useAnimation();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { ref: refHeading, inView: inViewHeading } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inViewHeading) {
      controlsHeading.start('visible');
    }
  }, [controlsHeading, inViewHeading]);

  const textAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 1 }
    }
  };

  return (
    <section className="feature-section flex flex-col font-code mt-10 w-full max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col items-center py-20 w-full bg-opacity-10 max-md:max-w-full">
        <div className="flex flex-col px-5 mt-2 w-full max-md:max-w-full">
          <motion.h2 ref={refHeading} initial="hidden" animate={controlsHeading} variants={textAnimation} className="uppercase text-4xl max-md:text-3xl font-bold text-center max-md:max-w-full">
            Our Key Features
          </motion.h2>
        </div>
        <div className="px-5 mt-20 w-full max-md:mt-10 max-md:max-w-full">
          <div className="feature-container flex gap-5 justify-center max-md:flex-col">
            {features.map((feature, index) => (
              <FeatureCard 
                key={index}
                feature={feature}
                index={index}
                setHoveredIndex={setHoveredIndex}
                hoveredIndex={hoveredIndex}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
