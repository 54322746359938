import React from 'react'
import Hero from '../components/Home/Hero'
import InfoSection from '../components/Home/InfoSection'
import ServiceGrid from '../components/Home/ServiceGrid'
import FeatureSection from '../components/Home/FeatureSection'
import ProjectsSection from '../components/Home/ProjectsSection'
import AboutSection from '../components/Home/AboutSection'


export default function HomeScreen() {
  return (
    <>
     <Hero/>
     <InfoSection/>
     <ServiceGrid/>
     <FeatureSection/>
     {/* <ProjectsSection/> */}
     <AboutSection/>
    </>
  )
}
