import React from 'react'
import Hero from '../components/About/Hero'
import Features from '../components/About/Features'
import AboutSection from '../components/About/AboutSection'
import Partners from '../components/About/Partners'

export default function About({theme}) {
  return (
    <>
     <Hero/>
     <Features theme={theme}/>
     <AboutSection/>
     {/* <Partners/> */}
    </>
  )
}
