
import React, { useEffect } from 'react';
import innovation from '../../assets/svgs/innovation.svg';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function AboutSection() {
  const controlsSection = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controlsSection.start('visible');
    }
  }, [controlsSection, inView]);

  const textAnimation = {
    hidden: { opacity: 0, x: 100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1 }
    }
  };

  const imageAnimation = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1 }
    }
  };

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  return (
    <section ref={ref} className="mt-20 px-20 font-code max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        <motion.div initial="hidden" animate={controlsSection} variants={imageAnimation} className="flex flex-col w-3/6 h-3/6 max-md:ml-0 max-md:w-full">
          <img src={innovation} alt="loading" />
        </motion.div>
        <div className="flex flex-col mt-56 ml-5 w-[45%] max-md:ml-0 max-md:mt-10 max-md:w-full">
          <motion.div initial="hidden" animate={controlsSection} variants={containerVariants} className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
            <motion.h2 variants={textAnimation} className="uppercase text-4xl max-md:text-3xl font-bold max-md:max-w-full">
              Our Commitment to Innovation
            </motion.h2>
            <motion.p variants={textAnimation} className="mt-14 text-lg max-md:text-sm max-md:mt-10 max-md:max-w-full">
              At the heart of our work is a deep commitment to innovation and excellence. We continuously strive to push the boundaries of technology, ensuring that our solutions are not only cutting-edge but also practical and user-friendly.
            </motion.p>
            <motion.p variants={textAnimation} className="mt-14 text-lg max-md:text-sm max-md:mt-10 max-md:max-w-full">
              Our team is dedicated to delivering results that make a difference, whether through advanced AI models, robust software architectures, or seamless cloud integrations.
            </motion.p>
            {/* <motion.a whileHover={ {scale:1.1}} variants={textAnimation} href="#" className="self-start px-4 py-3 rounded-full mt-14 text-base bg-[#076EAB] max-md:px-5 max-md:mt-10">
              Read more
            </motion.a> */}
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
